<template>
  <div>
    <!-- picD1BED30B320A2D3362F4DBE871D07283.jpg -->
    <img class="rounded img-large" src="">
    <p>
      Built your Creation Lab badge on My LEGO Network? Been dreaming of befriending the new faces from LEGO Universe?
    </p>
    <p>
      The time has come! Numb Chuck, Friendly Felix and Captain Jack Knife are eager to greet you <b>now</b> on My LEGO Network!
    </p>
    <p>
      Start your new adventure by lending a helping hand to <a href="http://mln.lego.com/en-us/Publicview/Friendly%20Felix.aspx" target="_blank">Friendly Felix</a>! Have fun!
    </p>
  </div>
</template>
